import React, { useState } from 'react';
import LogoBig from '../assets/logobig.png';
import Hero from  '../assets/headerpicture.png';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import NavBar from '../components/NavBar';
import MenuClose from '../assets/menuclose.png'


const Header = () => {
   const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <NavBar />
      <div className='header d-flex flex-column flex-md-row justify-content-between align-items-center mt-5 container'>
        <div className="header__logo mr-0 mr-md-5 d-flex align-items-center">
          <img src={LogoBig} alt="" className='mr-0 mr-md-5' />
        </div>
        <div className="header__hero mr-0 mr-md-5">
          <img className='mx-auto' src={Hero} alt="" />
        </div>
        <div className="header__social d-flex   align-items-center">
          <a href="https://www.facebook.com/zenenegubitevreme/" target="_blank">
            <img src={Fb} className="ml-0 ml-md-5 mr-2" alt="" />
          </a>
          <a href="https://www.instagram.com/zenenegubitevreme/" target="_blank">
            <img src={In} className="mr-2" alt="" />
          </a>
          <a href="https://zenenegubitevreme.rs/">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="video-show mt-3 mb-3" onClick={() => setOpen(prev => !prev)}>
        <h2>ПАНЕЛ ДИСКУСИЈА ЖЕНЕ НЕ ГУБИТЕ ВРЕМЕ</h2>
      </div>
      {isOpen &&
         <div className={`video-split w-100 position-relative  d-flex flex-column flex-md-row`}>
          <div className="w-100 w-md-50 p-3">
            <iframe width="100%" height="500px" src="https://www.youtube.com/embed/pTPg-C6HTZQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="w-100 w-md-50 p-3">
            <iframe width="100%" height="500px" src="https://www.youtube.com/embed/aW8XjJsgyyY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
            <div className="close-modal position-absolute" onClick={() => setOpen(false)}>
              <img src={MenuClose} alt="" />
            </div>
         </div>
      }
    </div>
  )
}

export default Header