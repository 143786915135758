import React from 'react';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Dragana from "../assets/profdraganavelickovictosic.png"


const DraganaVelickovic = () => {
  return (
    <div  className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Dragana} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>Проф. Драгана Величковић Тошић</h3>
        <p>Learning ville едукација за родитеље</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0">НЕЖНО, НЕЖНО НЕЖНИЈЕ</h3>
        <p className="mb-4">„И нас су тукли па шта нам фали“, чувена фраза којом се многе генерације „хвале”. Међутим, све је више покрета који се труде да нам покажу да постоје и другачији начини васпитања. Нежнији, брижнији, разумнији. Један од њих је свакако едукативна радионица „Learning ville“.</p>
          <a href="/dragana-velickovic">
            <button className="btn align-self-strech align-self-lg-start">
              ПРОЧИТАЈ ВИШЕ
            </button>
          </a>
      </div>
      <div id="nelavukomanovic"></div>
    </div>
  )
}

export default DraganaVelickovic