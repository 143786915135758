
import React, { Component } from "react";
import Footer from './components/Footer'
import Home from './pages/HomePage/HomePageTemplate'
import './App.scss'
import {Route, Switch} from 'react-router-dom'
import "bootstrap/scss/bootstrap.scss";
import Header from './components/Header';
import SubPage from './pages/subpages/SubPage';
import AleksandraBogdanovic from './pages/subpages/AleksandraBogdanovic';
import AnaBojcic from'./pages/subpages/AnaBojcic';
import AnaJovanovic from './pages/subpages/AnaJovanovic';
import BojanaVojnovic from './pages/subpages/BojanaVojnovic';
import DraganaVelickovic from './pages/subpages/DraganaVelickovic';
import MilenaCvorovic from './pages/subpages/MilenaCvorovic';
import NelaVukomanovic from './pages/subpages/NelaVukomanovic';
import SaraBiocanin from './pages/subpages/SaraBiocanin';
import IvanaDzamic from './pages/subpages/IvanaDzamic';


class App extends Component {
  render() {
    return <>
      <Switch>
        <Route  path="/" exact component={Home} />
        <Route  path="/aleksandra-bogdanovic" component={AleksandraBogdanovic} />
        <Route  path="/ana-bojcic"  component={AnaBojcic} />
        <Route  path="/ana-jovanovic" component={AnaJovanovic} />
        <Route  path="/bojana-vojnovic" component={BojanaVojnovic} />
        <Route  path="/dragana-velickovic" component={DraganaVelickovic} />
        <Route  path="/milena-cvorovic"  component={MilenaCvorovic} />
        <Route  path="/nela-vukomanovic" component={NelaVukomanovic} />
        <Route  path="/sara-biocanin" component={SaraBiocanin} />
        <Route  path="/ivana-dzamic" component={IvanaDzamic} />
      </Switch>
      <Footer />
    </>
  }
}


   export default App;
