import React from 'react';


const About = () => {
  return (
    <div>
      <div className="about d-flex align-items-center">
        <div className="container">
          <p className="about__text">
            Кампања <span className='font-weight-bold'>"Жене, не губите време" </span>
            спроводи се трећу годину за редом
            у оквиру Програма подршке развоју
            и промоцији женског иновационог
            предузетништва коју је покренуо <br/> <span className="font-weight-bold">
              Кабинет министра за иновације
            и технолошки развој.
            </span>
          </p>
        </div>
      </div>
      <div id="aleksandrabogdanovic" className="year-switch d-flex justify-content-center align-items-center">
        <p>2022</p>
        <p><a href="https://zenenegubitevreme.rs/2021">2021</a></p>
        <p><a href="https://zenenegubitevreme.rs/2020">2020</a></p>
      </div>
    </div>
  )
}

export default About