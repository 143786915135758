import React from 'react';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Milena from '../assets/milenacvorovic.png'


const MilenaCvorovic = () => {
  return (
    <div className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Milena} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>Милена Чворовић</h3>
        <p>АТЕЉЕ МИЛЕНЕ ЧВОРОВИЋ</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0 text-uppercase">Бунтовница с разлогом</h3>
        <p className="mb-4">Атеље Милене Чворовић је место за све оне који би да се упусте у модне воде: менторски програм, едукације из области модног маркетинга, и моделовање и конструкција одеће одакле је све базично и кренуло. </p>
            <a href="/milena-cvorovic">
              <button className="btn align-self-strech align-self-lg-start">
                ПРОЧИТАЈ ВИШЕ
              </button>
            </a>
      </div>
      <div id="bojanavojnovic"></div>
    </div>
  )
}

export default MilenaCvorovic