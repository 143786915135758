import React from 'react';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Ana from '../assets/ivanadzamic.png'


const IvanaDzamic = () => {
  return (
    <div className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Ana} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>Ивана Џамић Младеновић</h3>
        <p className='text-uppercase'>Fitsee</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0 text-uppercase">Смарт огледало</h3>
        <p className="mb-4">Fitsee је смарт огледало које је настало из велике жеље да се побољша мотивација људи да наставе да се баве спортом и заволе свој одраз у огледалу. Управо тако, наш модератор догађаја и суоснивач и бренд директор Ивана Џамић Младеновић, описује свој стартап који је основала заједно са својим супругом Јованом, са великом жељом да помогну људима широм света да јачају и менталну и физичку снагу.</p>
            <a href="/ivana-dzamic">
              <button className="btn align-self-strech align-self-lg-start">
                ПРОЧИТАЈ ВИШЕ
              </button>
            </a>
      </div>
    </div>
  )
}

export default IvanaDzamic