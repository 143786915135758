import React, { useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Sub from '../../assets/sub.jpg';
import Fb from '../../assets/fb.png';
import In from '../../assets/in.png';
import Web from '../../assets/web.png';
import Avatar from '../../assets/avatar.png';
import Aleksandra from '../../assets/aleksandrabogdanovic.png';
import DraganaVel from '../../assets/profdraganavelickovictosic.png';
import Nela from '../../assets/nelabuncicvukomanovic.png';
import AnaB from '../../assets/anabojcic.png';
import Sara from '../../assets/sarabiocanin.png';
import AnaJ from '../../assets/anajovanovic.png';
import Milena from '../../assets/milenacvorovic.png';
import Bojana from '../../assets/bojanavojnovic.png';
import Ivana from '../../assets/ivanadzamic.png';
import Cover from '../../assets/idzamic.jpg'



const IvanaDzamic = () => {

  useEffect(() => {

  })
  return (
    <>
    <div className="">
      <NavBar />
    </div>
      <div className="subpage container d-flex flex-column align-items-center" style={{backgroundImage: `url(${Cover})`}}>
        {/* <div className="d-flex justify-content-center subpage__social mt-auto ml-auto mb-4">
          <a href="">
            <img className='mr-2' src={Fb} alt="" />
          </a>
          <a href="">
            <img className='mr-2' src={In} alt="" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div> */}
      </div>
      <div className="container mt-5">
        <p className='text-subpage'>
          Fitsee је смарт огледало које је настало из велике жеље да се побољша мотивација људи да наставе да се баве спортом и заволе свој одраз у огледалу. Управо тако, наш модератор догађаја и суоснивач и бренд директор Ивана Џамић Младеновић, описује свој стартап који је основала заједно са својим супругом Јованом, са великом жељом да помогну људима широм света да јачају и менталну и физичку снагу.  <br /> <br />

„Као психолог, а и човек пре свега, увек сам имала невероватну потребу да помажем људима да постану најбоља верзија себе. Та вредност и дан данас води сваку пословну авантуру на коју сам се упутила и један од разлога зашто смо мој супруг и ја одлучили да се упустимо у свет стартапа и као млада екипа из Србије понудимо нешто иновативно свету, уз жељу да хуманизујемо технологију. Увек очекујем најбоље, поготово када нешто радимо из најчистијих намера, али сам и отворена за изазове које овакав један пут носи. У свету стартапа ствари се јако брзо одвијајају, а наше је да кроз превазилажење препрека, испунимо нашу сврху и заиста помогнемо људима.”
        </p>
        <br /> <br/>
        <hr />
        <div className="box-wrapper mx-auto">
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="dragana-velickovic" className="box-link">
              <div className="box-info text-center">
                <img src={DraganaVel} alt="" />
                <h2 className="text-uppercase">ПРОФ. ДРАГАНА ВЕЛИЧКОВИЋ ТОШИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="bojana-vojnovic">
              <div className="box-info text-center">
                <img src={Bojana} alt="" />
                <h2 className="text-uppercase">БОЈАНА ВОЈНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="nela-vukomanovic">
              <div className="box-info text-center">
                <img src={Nela} alt="" />
                <h2 className="text-uppercase">НЕЛА БУНЧИЋ ВУКОМАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="aleksandra-bogdanovic" className="box-link">
              <div className="box-info text-center">
                <img src={Aleksandra} alt="" />
                <h2 className="text-uppercase">АЛЕКСАНДРА БОГДАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="sara-biocanin">
              <div className="box-info text-center">
                <img src={Sara} alt="" />
                <h2 className="text-uppercase">САРА БИОЧАНИН</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-jovanovic">
              <div className="box-info text-center">
                <img src={AnaJ} alt="" />
                <h2 className="text-uppercase">АНА ЈОВАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a className="box-link" href="milena-cvorovic">
              <div className="box-info text-center">
                <img src={Milena} alt="" />
                <h2 className="text-uppercase">МИЛЕНА ЧВОРОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-bojcic">
              <div className="box-info text-center">
                <img src={AnaB} alt="" />
                <h2 className="text-uppercase">АНА БОЈЧИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ivana-dzamic">
              <div className="box-info text-center">
                <img src={Ivana} alt="" />
                <h2 className="text-uppercase">Ивана Џамић Младеновић</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default IvanaDzamic