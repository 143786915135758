import React from 'react';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Bojana from '../assets/bojanavojnovic.png'


const BojanaVojnovic = () => {
  return (
    <div className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Bojana} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>Бојана Војновић</h3>
        <p>AMPLITUDE MAGAZIN</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0 text-uppercase">Далеко од средине</h3>
        <p className="mb-4">„Amplitude magazin“ настао је као идеја да се отворено разговара о темама које су закључане у академским круговима, сензационализму или табуима. Како каже једна од оснивачица Бојана Војновић, то је „место“ које настоји да поврати „old fashion journalism“, место на ком се дискутује, истражује и пропитује без сензационализма.</p>
            <a href="/bojana-vojnovic">
              <button className="btn align-self-strech align-self-lg-start">
                ПРОЧИТАЈ ВИШЕ
              </button>
            </a>
      </div>
      <div id="ivanadzamic"></div>
    </div>
  )
}

export default BojanaVojnovic