import React from 'react';
import About from '../../components/About';
import Box from '../../components/Box';
import Header from '../../components/Header';
import AleksandraBogdanovic from '../../components/AleksandraBogdanovic';
import DraganaVelickovic from '../../components/DraganaVelickovic';
import NelaVukomanovic from '../../components/NelaVukomanovic';
import AnaBojcic from '../../components/AnaBojcic';
import SaraBiocanin from '../../components/SaraBiocanin';
import AnaJovanovic from '../../components/AnaJovanovic';
import MilenaCvorovic from '../../components/MilenaCvorovic';
import BojanaVojnovic from '../../components/BojanaVojnovic';
import IvanaDzamic from '../../components/IvanaDzamic';


const HomePageTemplate = () => {
  return (
    <div>
      <Header />
      <About />
      <AleksandraBogdanovic />
      <DraganaVelickovic />
      <NelaVukomanovic />
      <AnaBojcic />
      <SaraBiocanin />
      <AnaJovanovic />
      <MilenaCvorovic />
      <BojanaVojnovic />
      <IvanaDzamic />
    </div>
  )
}

export default HomePageTemplate