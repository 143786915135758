import React from 'react';
import Avatar from '../assets/avatar.png';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Nela from '../assets/nelabuncicvukomanovic.png'


const NelaVukomanovic = () => {
  return (
    <div className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Nela} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>НЕЛА БУНЧИЋ ВУКОМАНОВИЋ</h3>
        <p>Yugoslovenka</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0">ЗДРАВО ПРЕДУЗЕТНИШТВО</h3>
        <p className="mb-4">Нела Бунчић Вукомановић имала би много тога да каже и покаже када је реч о
предузимању иницијативе, али у њеном случају то није никакава мистификација:
вредна је, пожртвована, васпитана и културна, па резултат, у таквом односу
према животу, мора да се догоди као што се правда догоди иако је спора.</p>
           <a href="/nela-vukomanovic">
              <button className="btn align-self-strech align-self-lg-start">
                ПРОЧИТАЈ ВИШЕ
              </button>
            </a>
      </div>
      <div id="anabojcic"></div>
    </div>
  )
}

export default NelaVukomanovic