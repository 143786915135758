import React, {useState, useEffect} from 'react';
import LogoSmall from '../assets/logosmall.png'


const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
  window.addEventListener('scroll', pop);

  return () => window.removeEventListener('scroll', pop);
},[showMenu]);

const pop = () => {

    let navbar = document.getElementsByClassName('navbar-custom');

  if (window.scrollY > 105) {
    console.log('first')
    navbar[0].classList.add('sticky-menu');
  } else {
    navbar[0].classList.remove('sticky-menu');
  }
}

    return (
        <div className="d-flex navbar-custom">
            <div className="container d-flex align-items-center">
                <div className="logo">
                    <a href="https://zenenegubitevreme.rs/">
                        <img src={LogoSmall} alt="" />
                    </a>
            </div>
            <div className='nav ml-auto flex-column'>
                <label htmlFor="check">
                    <input type="checkbox" id="check" checked={showMenu === true} onClick={() => setShowMenu(!showMenu)}/>
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
            </div>
                {
                    showMenu === true ?
                    <div className="nav__links p-3">
                    <div className="container">
                        <ul>
                            <li><a onClick={() => setShowMenu(false)} href="/#aleksandrabogdanovic">АЛЕКСАНДРА БОГДАНОВИЋ</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#draganavelickovic">ПРОФ. ДРАГАНА ВЕЛИЧКОВИЋ ТОШИЋ</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#nelavukomanovic">НЕЛА БУНЧИЋ ВУКОМАНОВИЋ</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#anabojcic">АНА БОЈЧИЋ</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#sarabiocanin">САРА БИОЧАНИН</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#anajovanovic">АНА ЈОВАНОВИЋ</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#milenacvorovic">МИЛЕНА ЧВОРОВИЋ</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#bojanavojnovic">БОЈАНА ВОЈНОВИЋ</a></li>
                            <li><a onClick={() => setShowMenu(false)} href="/#ivanadzamic">ИВАНА ЏАМИЋ МЛАДЕНОВИЋ</a></li>
                        </ul>
                    </div>
                </div>
                : null
                }
            </div>
        </div>
     );
}




export default NavBar;