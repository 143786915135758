import React, { useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Sub from '../../assets/sub.jpg';
import Fb from '../../assets/fb.png';
import In from '../../assets/in.png';
import Web from '../../assets/web.png';
import Avatar from '../../assets/avatar.png';
import Aleksandra from '../../assets/aleksandrabogdanovic.png';
import DraganaVel from '../../assets/profdraganavelickovictosic.png';
import Nela from '../../assets/nelabuncicvukomanovic.png';
import AnaB from '../../assets/anabojcic.png';
import Sara from '../../assets/sarabiocanin.png';
import AnaJ from '../../assets/anajovanovic.png';
import Milena from '../../assets/milenacvorovic.png';
import Bojana from '../../assets/bojanavojnovic.png';
import Ivana from '../../assets/ivanadzamic.png';
import Cover from '../../assets/nvuko.jpg'



const NelaVukomanovic = () => {

  useEffect(() => {

  })
  return (
    <>
    <div className="">
      <NavBar />
    </div>
      <div className="subpage container d-flex flex-column align-items-center" style={{backgroundImage: `url(${Cover})`}}>
        <div className="d-flex justify-content-center subpage__social mt-auto ml-auto mb-4">
          <a href="">
            <img className='mr-2' src={Fb} alt="" />
          </a>
          <a href="">
            <img className='mr-2' src={In} alt="" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="container mt-5">

<p className="text-subpage">
  Нела Бунчић Вукомановић имала би много тога да каже и покаже када је реч о
предузимању иницијативе, али у њеном случају то није никакава мистификација:
вредна је, пожртвована, васпитана и културна, па резултат, у таквом односу
према животу, мора да се догоди као што се правда догоди иако је спора.
ТВ презентерка, новинарка, дама која се бави комуниацијама и односима с
јавношћу, креира садржај за клијенте, спортисткиња, маратонка – све је то Нела
коју дигитална јавност препознаје под псеудонимом yугословенка.
С елементима стрипске приче, као девојчица са 4 године, због проблема са
кривом кичмом, уписује се на модеран балет, за који не крије да јој је и данас
велика љубав. На такмичењу у Санрему осваја треће место, што је доводи у
Јутарњи програм РТС-а, њену другу љубав – телевизију. То препознаје и школски
психолог, саветујући је да се бави медијима, због чега уписује Факултет за
медије и комуникације. Држи да је формално образовање важно. „Та образовна
установа држи те у корак са временом”. <br/> <br/>
С пунолетством улази у круг фитнеса и трчања. Паралелно, пријавјује се на различите
ТВ конкурсе, и у том периоду почиње њен пут чије се улице гранају и данас, у
незаустављивим правцима – у комбинацији спорта и медија.
„У међувремену се Инстаграм доста развио, а ја сам имала искуства на претек
у прављењу кампања за клијенте, креирању садржаја и слично. Временом сам
кроз посао и лични развој савладала гро ствари, које данас свакодневно користим
на позицији маркетинг менаџера”, јасна је Нела.
Нелино предузетништво ослања се на микс бивствовања у развијеним медијским
системима и личног инфлуенс маркетинга, са додатком консалтинг услуга. Без
обзира на пословне обавезе, јутра јој почињу трчањем, која је воде до
најпрестижнијих планетарних маратона. Здраво предузетништво.
</p>
        <br /> <br/>
        <hr />
        <div className="box-wrapper mx-auto">
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="dragana-velickovic" className="box-link">
              <div className="box-info text-center">
                <img src={DraganaVel} alt="" />
                <h2 className="text-uppercase">ПРОФ. ДРАГАНА ВЕЛИЧКОВИЋ ТОШИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="bojana-vojnovic">
              <div className="box-info text-center">
                <img src={Bojana} alt="" />
                <h2 className="text-uppercase">БОЈАНА ВОЈНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="nela-vukomanovic">
              <div className="box-info text-center">
                <img src={Nela} alt="" />
                <h2 className="text-uppercase">НЕЛА БУНЧИЋ ВУКОМАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="aleksandra-bogdanovic" className="box-link">
              <div className="box-info text-center">
                <img src={Aleksandra} alt="" />
                <h2 className="text-uppercase">АЛЕКСАНДРА БОГДАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="sara-biocanin">
              <div className="box-info text-center">
                <img src={Sara} alt="" />
                <h2 className="text-uppercase">САРА БИОЧАНИН</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-jovanovic">
              <div className="box-info text-center">
                <img src={AnaJ} alt="" />
                <h2 className="text-uppercase">АНА ЈОВАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a className="box-link" href="milena-cvorovic">
              <div className="box-info text-center">
                <img src={Milena} alt="" />
                <h2 className="text-uppercase">МИЛЕНА ЧВОРОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-bojcic">
              <div className="box-info text-center">
                <img src={AnaB} alt="" />
                <h2 className="text-uppercase">АНА БОЈЧИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ivana-dzamic">
              <div className="box-info text-center">
                <img src={Ivana} alt="" />
                <h2 className="text-uppercase">Ивана Џамић Младеновић</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default NelaVukomanovic