import React from 'react';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Sara from '../assets/sarabiocanin.png'


const SaraBiocanin = () => {
  return (
    <div className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Sara} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>Сара Биочанин</h3>
        <p>KOORDINATA STREET</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0 text-uppercase">Координата из ината</h3>
        <p className="mb-4">Розбратна са прженим паприкама и суприм сосом. На улици. У пијаци. Уз винце. У маленом бистроу. Иза овакве деликатесне саге стоји двојац који чине Сара Биочанин и Стефан Живковић. Малу, али јединствену предузетничку причу, покренули су сами, вођени визијом да Београђанима, и свим обожаваоцима квалитетне хране, понуде нешто јединствено. </p>
           <a href="/sara-biocanin">
              <button className="btn align-self-strech align-self-lg-start">
                ПРОЧИТАЈ ВИШЕ
              </button>
            </a>
      </div>
      <div id="anajovanovic"></div>
    </div>
  )
}

export default SaraBiocanin