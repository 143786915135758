import React from 'react';
import Hash from '../assets/hash.jpg';
import Logo from '../assets/logobig.png';
import Ministarstvo from '../assets/ministarstvo.png';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';

const Footer = () => {
    return (
        <div className='footer'>
            <div className="footer__top  py-5">
                <div className="container d-flex flex-column justify-content-around align-items-center flex-md-row">
                    <div><img src={Hash} alt="" /></div>
                    <div className="d-flex flex-column align-items-stretch align-items-md-center w-100">
                        <p className='d-block mx-auto'>
                            Запрати нас на <br/>
                            ИНСТАГРАМУ <br />
                            @ŽENEnegubitevreme
                        </p>
                        <a className='d-block mx-auto' href="https://www.instagram.com/zenenegubitevreme/" target="_blank">
                            <button className="btn">
                            ZAPRATI NAS
                        </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer__bottom d-flex flex-column flex-md-row justify-content-between  container py-5">
                <div className="footer__logo w-100 py-3">
                    <a href="https://zenenegubitevreme.rs/" target="_blank">
                        <img className="d-block mx-auto py-2" src={Logo} alt="" />
                    </a>
                </div>
                <div className="footer__ministarstvo w-100 mb-3 mb-md-0">
                    <a href="https://inovacije.gov.rs/" target="_blank">
                        <img className="d-block mx-auto py-3" src={Ministarstvo} alt="" />
                    </a>
                </div>
                <div className="footer__social d-flex flex-column justify-content-center justify-content-md-end align-items-center w-100">
                    <div>
                        <div className="footer__social d-flex justify-content-center justify-content-md-end  align-items-center mb-3">
                            <a href="https://www.facebook.com/zenenegubitevreme/" target="_blank">
                                <img src={Fb} className="ml-0 ml-md-5 mr-2" alt="" />
                            </a>
                            <a href="https://www.instagram.com/zenenegubitevreme/" target="_blank">
                                <img src={In} className="mr-2" alt="" />
                            </a>
                            <a href="https://zenenegubitevreme.rs/">
                                <img src={Web} alt="" />
                            </a>
                        </div>
                        <p>Жене не губите време © 2022</p>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default Footer;