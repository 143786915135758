import React, { useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Sub from '../../assets/sub.jpg';
import Fb from '../../assets/fb.png';
import In from '../../assets/in.png';
import Web from '../../assets/web.png';
import Avatar from '../../assets/avatar.png';
import Aleksandra from '../../assets/aleksandrabogdanovic.png';
import DraganaVel from '../../assets/profdraganavelickovictosic.png';
import Nela from '../../assets/nelabuncicvukomanovic.png';
import AnaB from '../../assets/anabojcic.png';
import Sara from '../../assets/sarabiocanin.png';
import AnaJ from '../../assets/anajovanovic.png';
import Milena from '../../assets/milenacvorovic.png';
import Bojana from '../../assets/bojanavojnovic.png';
import Ivana from '../../assets/ivanadzamic.png';
import Cover from '../../assets/ajov.jpg'



const AnaJovanovic = () => {

  useEffect(() => {

  })
  return (
    <>
    <div className="">
      <NavBar />
    </div>
      <div className="subpage container d-flex flex-column align-items-center" style={{backgroundImage: `url(${Cover})`}}>
        {/* <div className="d-flex justify-content-center subpage__social mt-auto ml-auto mb-4">
          <a href="">
            <img className='mr-2' src={Fb} alt="" />
          </a>
          <a href="">
            <img className='mr-2' src={In} alt="" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div> */}
      </div>
      <div className="container mt-5">
        <p className="text-subpage">
          Кројачева школа је дигитална платформа за онлајн учење. Под њеном менторском палицом, прекопута, иза монитора налази се око тридесет хиљада онлајн полазника који овим путем стичу знања из различитих области, од програмирања до менаџерских вештина. Лична карта којом се представљају је тода су постали школа коју препоручују други дизајнери и програмери, а слоган по коме се препознају је - школа у којој се научи. <br /> <br/>
Док говори о супруговом и свом предузетничком путу, Ана Јовановић слика је и прилика некога ко ужива у ономе што ради, и ко се налази баш на оном месту које му припада и где треба да буде. Након двадесет година искуства предузетничке „вожње“, Ана звучи као да препричава врцкасте догађаје са матурантске екскурзије пре него да говори о озбиљним и формалним изазовима које ова прича са собом носи. Тајна је изгледа у томе што предузетништво види као нешто „предивно“ и напомиње да  сада не би могла да се замисли нигде другде. Све је почело још 2003. године када је пожелела да испод онога што је радила за друге, види свој потпис. Као познавалац маркетиншких прилика и неко ко уме да постави бизнис, удружила је снаге са супругом, стручњаком у ИТ области, тако је настала „Кројачева школа“. <br /> <br/>
Кренули су из свог стана са скоро никаквим почетним капиталом, оно што је био њихов бек ап је знање! Коментари околине сагласили су се око једног – да нису нормални. Дају фирми непопуларно име, школују сопствену конкуренцију... и још штошта што посматрачима није било јасно. Али како је хазардер по природи, Ана каже да се ниједног тренутка нису плашили, знали су да у овако родној земљи неће остати гладни, а све остало ће некако прегурати. Проблеми су проблеми, како дневни тако и они егзистенцијални, и иако не воли да их улепшава којекаквим називима, временом су они заиста постали само изазови , и то они после којих се иде на горе, после којих се напредује. После две деценије постојања вредност коју издваја иста је као и првог дана када их је било само двоје, примарна вредност су људи! Иако је комплетна платформа дигитална, онлајн, посебна пажња посвећена је томе да корисници не стичу утисак да је све аутоматизовано, већ да иза тих платформи стоје људи којима је заиста стало до њих, и који то потврђују пружајући не само знање, већ и енергију и посвећујући пажњу сваком детаљу.
Како Ана каже, свака предузетничка прича је донекле иста, свака мора да прође кроз тај један  шаблон, а када прегурате све „дечије болести“, онда је тренутак да застанете и кажете „ок, сада је време да направим паре“. На том путу, истиче, добро је имати партнера, а ако сте потуно сами струковна, удружења могу бити од велике користи.
        </p>
        <br /> <br/>
        <hr />
        <div className="box-wrapper mx-auto">
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="dragana-velickovic" className="box-link">
              <div className="box-info text-center">
                <img src={DraganaVel} alt="" />
                <h2 className="text-uppercase">ПРОФ. ДРАГАНА ВЕЛИЧКОВИЋ ТОШИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="bojana-vojnovic">
              <div className="box-info text-center">
                <img src={Bojana} alt="" />
                <h2 className="text-uppercase">БОЈАНА ВОЈНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="nela-vukomanovic">
              <div className="box-info text-center">
                <img src={Nela} alt="" />
                <h2 className="text-uppercase">НЕЛА БУНЧИЋ ВУКОМАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="aleksandra-bogdanovic" className="box-link">
              <div className="box-info text-center">
                <img src={Aleksandra} alt="" />
                <h2 className="text-uppercase">АЛЕКСАНДРА БОГДАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="sara-biocanin">
              <div className="box-info text-center">
                <img src={Sara} alt="" />
                <h2 className="text-uppercase">САРА БИОЧАНИН</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-jovanovic">
              <div className="box-info text-center">
                <img src={AnaJ} alt="" />
                <h2 className="text-uppercase">АНА ЈОВАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a className="box-link" href="milena-cvorovic">
              <div className="box-info text-center">
                <img src={Milena} alt="" />
                <h2 className="text-uppercase">МИЛЕНА ЧВОРОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-bojcic">
              <div className="box-info text-center">
                <img src={AnaB} alt="" />
                <h2 className="text-uppercase">АНА БОЈЧИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ivana-dzamic">
              <div className="box-info text-center">
                <img src={Ivana} alt="" />
                <h2 className="text-uppercase">Ивана Џамић Младеновић</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnaJovanovic