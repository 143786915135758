import React from 'react';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Ana from '../assets/anabojcic.png'


const AnaBojcic = () => {
  return (
    <div className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Ana} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>Ана Бојчић</h3>
        <p>ШКРАБАЦ</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0 text-uppercase">Буди своја мајица</h3>
        <p className="mb-4">Да ли бисте носили мајицу са натписом „жена змај“? Колико је само оваквих жаргонских фора и народних пословица које би требало послагати у некакав буквар. Управо ту њихову тежину препознала је Ана Бојчић са својим колегом Иваном, направивши бренд „Шкрабац“. Испоставило се да се на мајицама форе фино носе, и то с лакоћом. </p>
            <a href="/ana-bojcic">
              <button className="btn align-self-strech align-self-lg-start">
                ПРОЧИТАЈ ВИШЕ
              </button>
            </a>
      </div>
      <div id="sarabiocanin"></div>
    </div>
  )
}

export default AnaBojcic