import React, { Component } from "react";

const ProductContext = React.createContext();
class ProductProvider extends Component {
    state = {
        sidebarOpen: false,
    }

    handleSidebar = () => {
        this.setState({
            sidebarOpen:!this.state.sidebarOpen
        })
    }
    

  render() {
    return (
      <ProductContext.Provider value={
          {
            ...this.state,
            handleSidebar: this.handleSidebar
            }}>
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}

const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };
