import React, { useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Sub from '../../assets/sub.jpg';
import Fb from '../../assets/fb.png';
import In from '../../assets/in.png';
import Web from '../../assets/web.png';
import Avatar from '../../assets/avatar.png';
import Aleksandra from '../../assets/aleksandrabogdanovic.png';
import DraganaVel from '../../assets/profdraganavelickovictosic.png';
import Nela from '../../assets/nelabuncicvukomanovic.png';
import AnaB from '../../assets/anabojcic.png';
import Sara from '../../assets/sarabiocanin.png';
import AnaJ from '../../assets/anajovanovic.png';
import Milena from '../../assets/milenacvorovic.png';
import Bojana from '../../assets/bojanavojnovic.png';
import Ivana from '../../assets/ivanadzamic.png';
import Cover from '../../assets/abojc.jpg'



const AnaBojcic = () => {

  useEffect(() => {

  })
  return (
    <>
    <div className="">
      <NavBar />
    </div>
      <div className="subpage container d-flex flex-column align-items-center" style={{backgroundImage: `url(${Cover})`}}>
        {/* <div className="d-flex justify-content-center subpage__social mt-auto ml-auto mb-4">
          <a href="">
            <img className='mr-2' src={Fb} alt="" />
          </a>
          <a href="">
            <img className='mr-2' src={In} alt="" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div> */}
      </div>
      <div className="container mt-5">
        <p className='text-subpage'>
          Да ли бисте носили мајицу са натписом „жена змај“? Колико је само оваквих жаргонских фора и народних пословица које би требало послагати у некакав буквар. Управо ту њихову тежину препознала је Ана Бојчић са својим колегом Иваном, направивши бренд „Шкрабац“. Испоставило се да се на мајицама форе фино носе, и то с лакоћом. <br /> <br/>
„Шкрабац“ је фирма која се бави производњом гардеробе и аксесоара са шаљивим натписима из познатих анегдота. Ана, као један од оснивача бренда, по струци је дизајнер. Пилот пројекат био је како сама каже, јако скроман, међутим, осим што су одмах исплатили инвестицију од продатих мајица, нису дуго чекали на повратну информацију о главном питању сваког бизниса – да ли је производ добар? <br /> <br/>
Већ  пуних осам година расте број оних који би да свету поруче да: „су слободни стрелци“, да „ћуте само кад спавају“ да су тип особе који ће вас лако „послати у першун“, да су се промували само „на лепе очи“... Све смо то већ чули зар не, али овако одштампане на мајицама, ове доскочице чине да они који их носе већ припадају одређеној групи, „Шкрабац“ групи. <br /> <br/>
Зато, од клијената и стижу поруке у којима јављају да све више срећу људе из ове „екипе“, кажу, само се погледају у пролазу и уз осмех констатују - „Шкрабац“. <br /> <br/>
Можда су се неки тако и смували, ко зна. Енергија која влада иза бренда је лагана, бар тако изгледа слушајући Ану док мирно говори о препрекама које су морали да превладају током њиховог предузетничког пута. Каже да је велика предност што у старту уопште није знала шта их све чека, да као дизајнер није имала појма како изгледа производња једне мајице, како изабрати добре сараднике, како оглашавати бизнис и  још читав низ питања које је посао сам наметао. Али решавати ствари из дана у дан, бавити се њима, бити укључен у процес потпуно... то су савети које данас издваја.  Јер да је унапред знала шта је све чека, каже да је упитно  да ли би се уопште одважила да крене. <br /> <br/>
Оно што подвлачи неким будућим предузетницима је да изаберу да се баве нечим што воле, јер то је једини лек за оне дане када свега буде преко главе, „а буде их“. За своје производе се труде да бирају најквалитетније материјале, а одабир сарадника заснивају на сличним уверењима како рада тако и живота. Ако би морала да издвоји два савета, Ана каже да би то било два које је добила од маме и тате: „Никад немој да те је срамота да питаш кад нешто не знаш“ и „Иди где год те зову“, наизглед звуче банално али заправо отварају многа врата и могућности.
        </p>
        <br /> <br/>
        <hr />
        <div className="box-wrapper mx-auto">
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="dragana-velickovic" className="box-link">
              <div className="box-info text-center">
                <img src={DraganaVel} alt="" />
                <h2 className="text-uppercase">ПРОФ. ДРАГАНА ВЕЛИЧКОВИЋ ТОШИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="bojana-vojnovic">
              <div className="box-info text-center">
                <img src={Bojana} alt="" />
                <h2 className="text-uppercase">БОЈАНА ВОЈНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="nela-vukomanovic">
              <div className="box-info text-center">
                <img src={Nela} alt="" />
                <h2 className="text-uppercase">НЕЛА БУНЧИЋ ВУКОМАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a href="aleksandra-bogdanovic" className="box-link">
              <div className="box-info text-center">
                <img src={Aleksandra} alt="" />
                <h2 className="text-uppercase">АЛЕКСАНДРА БОГДАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="sara-biocanin">
              <div className="box-info text-center">
                <img src={Sara} alt="" />
                <h2 className="text-uppercase">САРА БИОЧАНИН</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-jovanovic">
              <div className="box-info text-center">
                <img src={AnaJ} alt="" />
                <h2 className="text-uppercase">АНА ЈОВАНОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
            <a className="box-link" href="milena-cvorovic">
              <div className="box-info text-center">
                <img src={Milena} alt="" />
                <h2 className="text-uppercase">МИЛЕНА ЧВОРОВИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ana-bojcic">
              <div className="box-info text-center">
                <img src={AnaB} alt="" />
                <h2 className="text-uppercase">АНА БОЈЧИЋ</h2>
                <p>оснивачица</p>
              </div>
            </a>
            <a className="box-link" href="ivana-dzamic">
              <div className="box-info text-center">
                <img src={Ivana} alt="" />
                <h2 className="text-uppercase">Ивана Џамић Младеновић</h2>
                <p>оснивачица</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnaBojcic