import React from 'react';
import Fb from '../assets/fb.png';
import In from '../assets/in.png';
import Web from '../assets/web.png';
import Ana from '../assets/anajovanovic.png'


const AnaJovanovic = () => {
  return (
    <div className="box container d-flex flex-column flex-md-row p-5">
      <div className="box__left pr-0 pr-md-5 d-flex flex-column justify-content-center">
        <img src={Ana} alt="" className="box__avatar" />
        <h3 className='text-uppercase'>Ана Јовановић</h3>
        <p>КРОЈАЧЕВА ШКОЛА</p>
        <div className="d-flex social justify-content-center mb-3 mb-lg-0">
          <a href="">
            <img src={Fb} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={In} alt="" className="mr-2" />
          </a>
          <a href="">
            <img src={Web} alt="" />
          </a>
        </div>
      </div>
      <div className="box__right pl-0 pl-md-5 d-flex flex-column justify-content-center">
        <h3 className="mb-4 mt-3 mt-lg-0 text-uppercase">Кројач по свачијој мери</h3>
        <p className="mb-4">Кројачева школа је дигитална платформа за онлајн учење. Под њеном менторском палицом, прекопута, иза монитора налази се око тридесет хиљада онлајн полазника који овим путем стичу знања из различитих области, од програмирања до менаџерских вештина. Лична карта којом се представљају је тода су постали школа коју препоручују други дизајнери и програмери, а слоган по коме се препознају је - школа у којој се научи.</p>
          <a href="/ana-jovanovic">
            <button className="btn align-self-strech align-self-lg-start">
              ПРОЧИТАЈ ВИШЕ
            </button>
          </a>
      </div>
      <div id="milenacvorovic"></div>
    </div>
  )
}

export default AnaJovanovic